import React from "react"
import { graphql } from "gatsby"

import { Reset as Page } from "../../components/Account/Reset/Reset"

export const query = graphql`
  query {
    page: sanityPageAccountReset {
      title
      image {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
