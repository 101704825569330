import React from "react"

export const withReset = Component => ({ name = "Reset", page, ...props }) => {
  const title = page?.title
  const image = page?.image?.asset?.fluid

  const paths = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Reset",
    },
  ]

  Component.displayName = name
  return <Component {...props} title={title} image={image} paths={paths} />
}
