import React from "react"

import { useCustomerAccount, useShowPassword } from "../../../hooks/useCustomer"

export const withResetForm = Component => ({ name = "ResetForm", ...props }) => {
  const { resetCustomer, data, setData, loading, errors } = useCustomerAccount()
  const { showPassword, setShowPassword } = useShowPassword()

  const resetParams = props?.location?.pathname.split("/account/reset/")[1].split("/") || []
  const customerId = (resetParams && resetParams[0]) || null
  const resetToken = (resetParams && resetParams[1]) || null

  const handleSubmit = async event => {
    event.preventDefault()
    await resetCustomer(customerId, resetToken, data?.password)
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const handleShowPassword = () => {
    setShowPassword(prevState => !prevState)
  }

  const isInvalid = data.password !== data.confirmedPassword

  Component.displayName = name

  return (
    <Component
      {...props}
      data={data}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
      showPassword={showPassword}
      handleShowPassword={handleShowPassword}
      isInvalid={isInvalid}
    />
  )
}
