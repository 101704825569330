import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withResetForm } from "./withResetForm"
import Icon from "../../Icon"
import FormInput from "../../Form/FormInput"
import { StyledButton } from "../../Styled/Button"

const Input = styled(FormInput)`
  ${tw`mb-2-4`}
`

const InputWrapper = tw.div`
  relative
`

const StyledIcon = styled(Icon)`
  ${tw`absolute right-0 top-0-6`}
`

const Error = tw.div`
  text-16 mb-2-4 text-orange
`

const Submit = styled(StyledButton)`
  ${tw`w-full mt-1-6`}
`

export const ResetForm = withResetForm(({ data, handleChange, handleSubmit, loading, errors, showPassword, handleShowPassword, isInvalid }) => (
  <form onSubmit={handleSubmit}>
    <InputWrapper>
      <Input
        name={`password`}
        type={showPassword ? `text` : `password`}
        placeholder={`Password`}
        placeholderColor={`dark`}
        value={data?.password}
        handleChange={handleChange}
      />
      <StyledIcon name={!showPassword ? `show` : `hide`} size={`xsmall`} onClick={handleShowPassword} />
    </InputWrapper>
    <Input
      name={`confirmedPassword`}
      type={`password`}
      placeholder={`Confirm password`}
      placeholderColor={`dark`}
      value={data?.confirmedPassword}
      handleChange={handleChange}
    />
    {errors?.length > 0 &&
      errors?.filter(error => error?.field?.length && error?.field[1] === "password").map(error => <Error>{error?.message}</Error>)}
    <Submit type={`submit`} height={`56`} textSize={`16`} colour={`green`} disabled={loading || isInvalid}>
      Reset password
    </Submit>
  </form>
))

ResetForm.propTypes = {
  data: PropTypes.shape({
    password: PropTypes.string,
    confirmedPassword: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  errors: PropTypes.any,
  showPassword: PropTypes.bool,
  handleShowPassword: PropTypes.func,
  isInvalid: PropTypes.bool,
}
