import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withReset } from "./withReset"
import { ResetForm } from "./ResetForm"
import { BackgroundContainer, StyledContainer } from "../../Styled/Container"
import ImageContent from "../../ImageContent"
import { GatsbyImageProps } from "../../../props/ImageProps"
import Breadcrumb from "../../Breadcrumb/Breadcrumb"

const Container = styled(StyledContainer)`
  ${tw`relative max-w-xl mx-auto bg-light md:bg-lighter`}
`

const Content = tw.div`
  max-w-59-2 md:max-w-72 mx-auto px-2-4 md:pl-4-8 md:pr-7-2 lg:pl-9-6 lg:pr-10-4 h-full flex justify-center flex-col py-8-8 md:py-0
`

const Title = tw.h1`
  text-38 leading-1.21 md:text-44 md:leading-1.45 font-display text-center mb-4
`

export const Reset = withReset(({ title, image, paths }) => {
  return (
    <BackgroundContainer background={`light`}>
      <Container width={"full"}>
        {paths && <Breadcrumb paths={paths} />}
        <ImageContent image={image} mobileHidden>
          <Content>
            <Title>{title}</Title>
            <ResetForm />
          </Content>
        </ImageContent>
      </Container>
    </BackgroundContainer>
  )
})

Reset.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape(GatsbyImageProps),
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}
